/* html, body, #app, #root>div {
  height: 100%
}
#root{
  height:100%
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Prevent chrome from using the overscroll to go back */
  overscroll-behavior-x: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .css-yem0l2-MuiPopper-root-MuiTooltip-popper{
  display:none
} */
.MuiTooltip-tooltip {
  max-width: 30rem !important;
}

.MuiInputBase-root.MuiInput-root,
.MuiFormLabel-root {
  margin-top: 0px !important;
}

/* body {
  transform: scale(0.8);
  transform-origin: 0 0;
  width: 125%; /* Compensate for the reduced width due to scaling */
/* } */