.marksubmission {

  .MuiDataGrid-columnHeaderTitle {
    line-height: normal !important;
    text-overflow: unset !important;
    white-space: normal !important;
    font-size: 0.8rem !important;
  }
  .MuiSelect-select {
    font-size: 0.8rem !important;
    padding-right: 20px !important;
  }
  .MuiDataGrid-cellContent {
    font-size: 0.8rem !important;
  }
  .MuiDataGrid-cell {
    padding: 0 5px !important;
  }
  .MuiOutlinedInput-input{
    font-size: 0.8rem !important;
  }
  .MuiTypography-root{
    font-size: 0.8rem !important;
  }
  .Mui-selected{
    background-color: white !important;
  }
  .MuiDataGrid-selectedRowCount{
    visibility: hidden !important;
  }
  .MuiFormLabel-root.MuiInputLabel-root{
    font-size: 0.8rem !important;
  }
  .MuiInputBase-root{
    margin-top:0px;
  }

  .Mui-disabled {
    opacity:0.7;
  }

  /* Set height of table to 100% so user doesn't have to be over a row to scroll right and left */
  .MuiTableContainer-root {
    height:100%;
  }

  /* Hide the expand column for GCSE and Entry Level */
  .EntryLevelTable .MuiTableHead-root > tr > th:nth-child(7),
  .EntryLevelTable .MuiTableBody-root > tr > td:nth-child(7),
  .GCSETable .MuiTableHead-root > tr > th:nth-child(5),
  .GCSETable .MuiTableBody-root > tr > td:nth-child(5) {
    display:none;
  }

  /* Hide borders of fields that are mean't to span multiple rows */
  .GCSETable .candidate_name,
  .GCSETable .candidate_number,
  .GCSETable .sex,
  .GCSETable .overallmark,
  .EntryLevelTable .candidate_name,
  .EntryLevelTable .candidate_number,
  .EntryLevelTable .sex,
  .EntryLevelTable .overallmark
  {
    border-bottom:0 !important;
  }

  .GCSETable .candidate_name_childrow,
  .GCSETable .candidate_number_childrow,
  .GCSETable .sex_childrow,
  .GCSETable .overallmark_childrow,
  .EntryLevelTable .candidate_name_childrow,
  .EntryLevelTable .candidate_number_childrow,
  .EntryLevelTable .sex_childrow,
  .EntryLevelTable .overallmark_childrow
  {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }

  /* Wrap column headers */
  .Mui-TableHeadCell-Content-Wrapper
  {
    white-space: wrap !important;
  }

  /* Add margin to bottom of body to avoid scroll bar getting in the way */
  .MuiTableBody-root {
    margin-bottom:30px;
  }

}
