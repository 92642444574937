
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiTableRow-root td, .MuiTableRow-root th{
    border: 1px solid rgba(224, 224, 224, 1) !important;
    border-left: none !important;
  }

 .MuiTableCell-head {
     border: 1px solid rgba(224, 224, 224, 1) !important; border-top: none !important;

 }
 .MuiTableContainer-root{
     box-shadow: none !important;
     border-top: none !important;
 }

.MuiTableContainer-root input.Mui-disabled,
.MuiTableContainer-root div[role=combobox].Mui-disabled {
  background-color:#ccc;
}

.MuiTableContainer-root div[role=combobox].Mui-disabled

 .TableForm{
     margin-top:1em;
 }
 .TableForm .MuiInputBase-input.MuiOutlinedInput-input{
    padding: 9px 12px !important;
    text-align: center;
 }

 .TableForm tbody > tr:last-child  > td,  .TableForm tbody > tr:last-child  > th{
    border-bottom: 0 !important;
  }
  .TableForm tbody > tr > td:last-child {
      border-right: 0 !important;
  }

  .TableForm thead > tr > th:last-child {
    border-right: 0 !important;
}

.TableForm thead > tr > th:first-child {
    border-left: 0 !important;
}
.TableForm thead > tr > th {
    border-top: 0 !important;
}
.TableForm .MuiInputBase-root {
    margin-top:0px !important;
}
.textField_bg{
    background-color: #e4e4e4;
}
